<template>
	<section class="mt-4">
        <div>
            <tabs :tabs="rutas" class="border-b-gris" />
            <router-view />
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            rutas: [
                { titulo: 'PayU', ruta: 'admin.tesoreria.pasarela-pagos.payU' },
                { titulo: 'Mercado pago', ruta: 'admin.tesoreria.pasarela-pagos.mercadopago' },
            ]
        }
    },
    watch: {
    },
	mounted(){
	}
}
</script>

<style lang="scss" scoped>

</style>